<template>
  <div class="container">
    <div class="login">
      <form @submit.prevent="fazerLogin" :validation-schema="mySchema">
        <table>
          <tr>
            <td class="td-email">
              <label>Email:</label>
              <input
                class="inp-email"
                id="email"
                type="email"
                name="email"
                placeholder="digite o usuário"
                autocomplete="on"
                required
                v-model="mySchema.email"
              />
              <ErrorMessage name="email" />
            </td>
          </tr>
          <tr>
            <td class="td-senha">
              <label>Senha:</label>
              <input
                class="inp-senha"
                id="password"
                type="password"
                name="password"
                placeholder="digite a senha"
                autocomplete="off"
                required
                v-model="mySchema.password"
              />
              <ErrorMessage name="password" />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <input class="botao-enviar" type="submit" value="Logar-se" />
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import UserLogin from "../services/LoginApi";
// import * as yup from "yup";
export default {
  data() {
    return {
      mySchema: {
        email: "adm@postomoradadosol.com.br",
        password: "hhhhhhhhhh",
      },
      // mySchema: yup.object().shape({
      //   email: yup
      //     .string()
      //     .email("e-Mail inválido!")
      //     .required("Campo obrigatório!"),

      //   password: yup
      //     .string()
      //     .min(5, (data) => `minimo de ${data.min} caracteres`)
      //     .required((data) => `${data.label} adm@posto`)
      //     .label("Password"),
      // }),
    };
  },

  methods: {
    //funcao para fazer login
    fazerLogin() {
      UserLogin.apiLogin(this.mySchema.email).then((resposta) => {
        //para ver (Inspesionar e ir na aba Aplicativo)
        localStorage.setItem("userName", JSON.stringify(resposta.data.nome));
        localStorage.setItem(
          "userCodOpe",
          JSON.stringify(resposta.data.codope)
        );
        localStorage.setItem(
          "userCodPosto",
          JSON.stringify(resposta.data.codposto)
        );

        this.clearForm();
        this.$router.push({ name: "capa" });
      });
    },
    //   if (!this.usuarioJaLogado || !this.usuarioJaLogado[0] || !this.mySchema.email) {
    //   alert("Usuario e/ou senha incorretos");
    //   this.clearForm();
    // }

    // if (user.password !== this.mySchema.password) {
    //   alert("Usuario e/ou senha incorretos");
    //   this.clearForm();
    // }

    // localStorage.setItem("authUser", JSON.stringify(user));
    // this.$router.push({ name: "capa" });

    //limpa o form
    clearForm() {
      this.mySchema = {
        email: "",
        password: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vh;
  min-height: 40vh;
  background-color: #837d7d;
  margin: 10px;
  padding: 10px;
  border-radius: 35px;
  border: 3px solid rgb(158, 93, 9);
  border-radius: 20px;
  box-shadow: 0 20px 30px rgb(151, 139, 108), 0 40px 30px rgba(69, 7, 7, 0.22);
}
.login {
  display: flex;
  left: 0px;
  top: 0px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
  margin: auto;
  background-color: rgb(239, 236, 233);
  border-radius: 10px;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
  border-left: 3px solid rgb(104, 70, 6);
  border-right: 3px solid rgb(104, 70, 6);
}

.td-email {
  margin-bottom: 15px;
  padding: 10px;
}

.inp-email {
  width: 300px;
  color: brown;
  font-size: 1em;
  border: 1px solid blue;
  border-radius: 5px;
}

.inp-senha {
  width: 300px;
  color: brown;
  font-size: 1em;
  border: 1px solid blue;
  border-radius: 5px;
}

input:focus {
  background: yellow;
  border: 2px solid blue;
}

.botao-enviar {
  width: 200px;
  color: blue;
  font-size: 1em;
  border: 1px solid brown;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
}
</style>
