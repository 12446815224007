<template>
  <div class="navegacao borda">
    <router-view />
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.navegacao {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90vh;
  min-height: 60vh;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #e5e0dd;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
}
</style>
