<template>
  <div class="principal">
    <div class="div1">
      <article class="article">
        <ul>
          <li>
            <strong>INSTRUÇÕES DE FUNCIONAMENTO:</strong>
          </li>

          <li>
            Esta página tem como finalidade criar um elo de ligação entre os
            cadastros postos da Rede Rabi.
          </li>

          <li>
            Cada unidade vai ter 2 acessos um do financeiro e outro para os
            caixas.
          </li>
          <li>
            O financeiro vai poder inserir e alterar cadastros, bem como
            alimentar os mesmos com as intercorrências sobre os clientes.
          </li>
          <li>
            O caixa vai poder consultar instantaneamente o situação do cadastro.
          </li>
          <li>
            como pagamentos em atrasos, negociações de preços, bloqueios...
          </li>
          <li>
            Vai ser de responsábelidade de cada unidade o lançamento dos dados.
          </li>
          <li>
            Busca rapida de cadastros com Inscrição Estadual. (Consulta
            CNPJ-api).
          </li>
        </ul>
      </article>
    </div>

    <div class="div1">
      <article class="article">
        <ul>
          <li>
            <strong>NOTAS E AVISOS EM GERAL:</strong>
          </li>

          <li>Caso tenhamos no foturo um sistema só para todos os postos,</li>

          <li>
            vamos aproveitar esse mesmo cadastro, com alguns pequenos ajustes.
          </li>
          <li>
            <UsuarioLogadoVue userName />
          </li>
          <li>Usúario{{ userName }}</li>
          <li>Cód Ope:{{ userCodOpe }}</li>
          <li>Cód Posto:{{ userCodPosto }}</li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
      userCodOpe: "",
      userCodPosto: "",
    };
  },
  mounted() {
    this.userName = localStorage.getItem("userName") || "";
    this.userCodOpe = localStorage.getItem("userCodOpe") || "";
    this.userCodPosto = localStorage.getItem("userCodPosto") || "";
    //fica monitorando
    // this.$watch(
    //   () => localStorage.getItem("userName"),
    //   (newVal) => {
    //     this.userName = newVal;
    //   }
    // );
  },
};
</script>

<style lang="scss" scoped>
.principal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 60vh;
  background-color: #302c2c;
  margin: 10px;
  padding: 10px;
  border-radius: 35px;
}

.div1 {
  display: flex;
  margin: auto;
  width: 100%;
  height: 300px;
  margin: 20px;
  margin-top: 0;
  align-items: center;
  justify-content: center;
  background: #ccc5c5;
  border: 3px solid rgb(68, 41, 5);
  border-radius: 20px;
  box-shadow: 0 20px 30px rgb(217, 200, 155), 0 40px 30px rgba(87, 66, 66, 0.22);
}
.div-user {
  display: flex;
  margin: auto;
  width: 100%;
  height: 30px;
  margin: 5px;
  margin-top: 0;
  align-items: center;
  justify-content: center;
  background: #ccc5c5;
  // border: 3px solid rgb(68, 41, 5);
  // border-radius: 20px;
  // box-shadow: 0 20px 30px rgb(217, 200, 155), 0 40px 30px rgba(87, 66, 66, 0.22);
}

.article {
  color: rgba(141, 16, 16, 0.887);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1em;
  text-align: left;
}
</style>

<!-- <template>
  <div class="capa">
    <img class="logorabi" alt="logo Rabi" src="../assets/logo_rabi.jpg" />
    <MsgInicial msg="SEJA MUITO BEM VINDO A REDE RABI DE POSTOS!" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MsgInicial from "../components/MsgInicial.vue";

@Options({
  components: {
    MsgInicial,
  },
})
export default class MsgInicialE extends Vue {}
</script> -->
