import { createRouter, createWebHistory } from "vue-router";
import ConstrucaoView from "../pages/ConstrucaoView.vue";
import CapaView from "../views/CapaView.vue";
import LoginView from "../views/LoginView.vue";

const routes = [
  {
    path: "/",
    name: "capa",
    component: CapaView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/construcao",
    name: "construcao",
    component: ConstrucaoView,
  },
  {
    path: "/clientesLista",
    name: "clientesLista",
    component: () => import("../pages/ClientesListaView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    //Lista todos os registros
    path: "/moviListaTodos",
    name: "moviListaTodos",
    component: () => import("../pages/MoviListaTodosView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/clienteInsere",
    name: "clienteInsere",
    component: () => import("../pages/ClientesInsereView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: () => import("../pages/UsuariosView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/postos",
    name: "postos",
    component: () => import("../pages/PostosView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/feriados",
    name: "feriados",
    component: () => import("../pages/ConsultaFeriadosView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/buscarcep",
    name: "buscarcep",
    component: () => import("../pages/BuscarCepView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/buscarcnpj",
    name: "buscarcnpj",
    component: () => import("../pages/BuscarCnpjaView.vue"),
    meta: {
      requerAuth: true,
    },
  },
  {
    path: "/clientespainel",
    name: "clientespainel",
    component: () => import("../pages/ClientesPainelView.vue"),
    meta: {
      requerAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Antes de entrar em qualquer rota verefica
router.beforeEach((to, from, next) => {
  //verefica se o usuario estiver logado
  if (to.name !== "login" && !localStorage.getItem("userName")) {
    next({ name: "login" });
  } else {
    //está logado, pode continuar
    next();
  }
});

export default router;
