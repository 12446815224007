<template>
  <HeaderView />
  <MenuView />
  <NavegacaoView />
</template>

<script>
import HeaderView from "./views/HeaderView.vue";
import MenuView from "./views/MenuView.vue";
import NavegacaoView from "./views/NavegacaoView.vue";

export default {
  components: {
    HeaderView,
    MenuView,
    NavegacaoView,
  },
  // computed: {
  //   notIsLoginPage() {
  //     return this.$route.name !== "clientesLista";
  //   },
  // },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.menu {
  background-color: rgb(226, 185, 144);
  border-radius: 10px;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
  border-left: 3px solid rgb(104, 70, 6);
  border-right: 3px solid rgb(104, 70, 6);
  padding: 15px;
}
</style>
