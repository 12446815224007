<template>
  <div class="lista">
    <table class="tabela">
      <tr>
        <th class="thcabe taNOME">
          <H1> PÁGINA EM CONSTRUÇÃO...</H1>
        </th>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.lista {
  display: flex;
  left: 0px;
  top: 0px;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: auto;
  background-color: rgb(239, 236, 233);
  border-radius: 10px;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
  border-left: 3px solid rgb(104, 70, 6);
  border-right: 3px solid rgb(104, 70, 6);
}

.tabela {
  width: 95%;
  height: 95%;
  margin: auto;
  border-spacing: 0px;
  border-style: dotted;
  border-width: 2px;
  border-color: rgb(226, 103, 21);
  box-sizing: border-box;
}

.thcabe {
  background-color: rgb(183, 196, 162);
  border: 1px solid rgb(65, 29, 29);
  border-collapse: collapse;
  border-radius: 5px;
  padding: 2px;
}

.tdcol {
  border: 1px dotted rgb(32, 41, 31);
  border-collapse: collapse;
  border-radius: 6px;
  padding: 5px;
}

.tacode {
  width: 5%;
  text-align: left;
}

.tanome {
  width: 30%;
  text-align: left;
}

.tacnpj {
  width: 10%;
  text-align: left;
}

.taemail {
  width: 25%;
  text-align: left;
}

.tafone {
  width: 10%;
  text-align: left;
}

.tacidade {
  width: 20%;
  text-align: left;
}

.trlinhas:nth-child(odd) {
  /* linhas impares */
  background-color: #f8f5f5;
  border: 1px dotted rgb(70, 105, 69);
  border-collapse: collapse;
  border-radius: 3px;
  padding: 5px;
}

.trlinhas:nth-child(even) {
  /* linhas pares */
  background-color: #ebeadd;
  border: 1px dotted rgb(52, 61, 52);
  border-collapse: collapse;
  border-radius: 3px;
  padding: 5px;
}

.trlinhas:hover {
  background-color: #a2c3c8;
  transform: scale(1.01);
  cursor: default;
  box-shadow: 2px 2px 12px rgb(34, 9, 9), -1px -1px 8px rgb(27, 3, 3);
}

a:hover {
  font-size: 1.1em;
  font-style: italic;
  color: blue;
  cursor: pointer;
}
</style>

