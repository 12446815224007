<template>
  <div>
    <!-- v-if="isLogged" -->
    <div class="menu">
      <nav class="nav">
        <router-link to="/"><span>Inicio</span></router-link>
        |
        <router-link to="/clientesLista"
          ><span>Pesq. Clientes</span></router-link
        >
        |
        <router-link to="/clienteInsere"
          ><span>Cad. Clientes</span></router-link
        >
        |
        <router-link to="/buscarcnpj"
          ><span>Consulta CNPJ(api)</span></router-link
        >
        |
        <router-link to="/usuarios"><span>Usuários</span></router-link>
        |
        <router-link to="/postos"><span>Postos</span></router-link>
        |
        <router-link to="/feriados"><span>Feriados</span></router-link>
        |
        <router-link to="/construcao"><span>CEP</span></router-link>
        |
        <router-link to="/clientespainel"><span>Painel</span></router-link>
        |
        <button class="nav-botao" @click="logout()">Sair</button>
      </nav>
    </div>
    <!-- <div class="menu" v-else>
      <nav class="nav">
        |||
        <router-link to="/login"><span>Logar-se</span></router-link>
        |||
      </nav>
    </div> -->
  </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     //pegando o id do usuario logado
  //     dadosUsuarioLogado: {
  //       id: JSON.parse(localStorage.getItem("authUser").id),
  //     },
  //   };
  // },
  // computed: {
  //   notIsLoginPage() {
  //     return this.$route.name !== "login";
  //   },
  // },

  methods: {
    //deslogando
    logout() {
      if (!localStorage.getItem("storageUsuario") == null) {
        localStorage.removeItem("storageUsuario");
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  margin: auto;
  width: 98%;
  align-items: center;
  justify-content: center;
  background: #b3a3a3;
  border-bottom: 6px solid rgb(104, 70, 6);
  border-top: 6px solid rgb(104, 70, 6);
  box-shadow: 0 19px 38px rgb(153, 140, 106), 0 3px 3px rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}
.nav {
  padding: 5px 10px;
}

.nav a {
  color: #7b3707;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.5s;
}

.nav-botao {
  cursor: pointer;
  color: blue;
  border-radius: 5px;
}

.nav a:hover {
  color: blue;
  //font-size: 1.1em;
  font-weight: 500;

  span {
    border: solid 1px blue;
    border-radius: 5px;
    padding: 3px;
    transition: 0.5s;
  }
}
</style>
